<template>
  <div>
    <section
      id="dashboard-blog"
      class="section section-secondary jpadding blog"
    >
      <div v-if="isLoading" key="spinner" class="text-center loading-container">
        <i class="text-blue fad fa-spinner-third fa-spin fa-3x"></i>
        <p class="mt-3">Loading blog categories...</p>
      </div>
      <div v-if="showError" key="error" class="jcard-content text-center">
        <h5 class="mb-3">
          <i class="fad fa-exclamation-circle text-blue fa-3x"></i><br />
        </h5>
        <p>
          Unable to fetch blog categories from the servers at the moment.
        </p>
      </div>

      <section v-if="!isLoading && !showError" key="content" class="articles">
        <div class="d-flex">
          <h5 class="articles-title">Blog Categories</h5>
          <a
            href="#"
            @click="showAddCategoryDialog"
            class="ml-auto jbtn-scale jbtn-hover-blue"
            ><i class="fad fa-plus"></i
          ></a>
        </div>
        <div class="articles-list-3">
          <div
            v-for="category in categories"
            :key="category.id"
            class="jcard-article-category"
          >
            <div class="jcard-article-content p-3">
              <h5>{{ category.name }}</h5>
              <div class="detail-sm">{{ category.link_name }}</div>
            </div>
            <div class="jcard-article-btns">
              <b-dropdown
                right
                variant="link"
                toggle-class="jbtn jbtn-icon jbtn-icon-link"
                no-caret
              >
                <template #button-content>
                  <i class="fa fa-ellipsis-v"></i>
                </template>
                <b-dropdown-item @click="showEditCategoryDialog(category)"
                  ><i class="fal fa-pencil mr-2"></i> Edit
                  category</b-dropdown-item
                >
                <b-dropdown-item @click="deleteBlogCategory(category.id)"
                  ><i class="fal fa-trash mr-2"></i> Delete
                  category</b-dropdown-item
                >
                <b-dropdown-item
                  v-bind:href="
                    'https://talkii.app/blog/category/' + category.link_name
                  "
                  target="_blank"
                  ><i class="fal fa-external-link mr-2"></i> Show
                  articles</b-dropdown-item
                >
              </b-dropdown>
            </div>
          </div>
        </div>
      </section>
    </section>

    <!-- ----- add category modal --------------------------------- -->
    <b-modal
      hide-header="true"
      hide-footer="true"
      id="add-category-modal"
      ref="add-category-modal"
      centered
      title="BootstrapVue"
    >
      <div class="jdialog-top">
        <div class="close-container">
          <h3>
            <strong>{{ editMode ? "Edit" : "Add" }} blog category</strong>
          </h3>
          <span
            @click="$bvModal.hide('add-category-modal')"
            class="ml-auto jclose"
            ><i class="fa fa-times "></i
          ></span>
        </div>
        <p>
          <small
            >Please specify a link name for this category. Remember all link
            names should be lower case, separated by a '-'</small
          >
        </p>
      </div>
      <div class="jdialog-main">
        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label for="name">
                <i class="fal fa-file-alt"></i> Category name
              </label>
              <input
                type="text"
                v-bind:class="{
                  'form-control': true,
                  'is-invalid':
                    !validInput(newCategory.name) && bluredNewCategory,
                }"
                v-on:blur="bluredNewCategory = true"
                v-model="newCategory.name"
              />
              <div class="invalid-feedback">
                Please enter something here.
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md">
            <div class="form-group">
              <label for="email"
                ><i class="fal fa-link"></i> Category link name</label
              >
              <input
                type="text"
                v-bind:class="{
                  'form-control': true,
                  'is-invalid':
                    !validLinkName(newCategory.link_name) &&
                    bluredCategoryLinkName,
                }"
                v-on:blur="bluredCategoryLinkName = true"
                v-model="newCategory.link_name"
              />
              <small
                >https://talkii.app/blog/category/<strong>{{
                  newCategory.link_name
                }}</strong></small
              >
              <div class="invalid-feedback">
                Please enter a valid link name. Only letters and numbers
                separated by '-'.
              </div>
            </div>
          </div>
        </div>

        <b-alert v-if="linkNameExists" show variant="danger"
          ><i class="fad fa-exclamation-circle"></i>
          {{ validationMsg }}
        </b-alert>
      </div>
      <div class="jdialog-bottom with-cancel">
        <button
          @click="$bvModal.hide('add-category-modal')"
          class="jbtn jbtn-sm jbtn-red"
        >
          <i class="fa fa-times"></i> Cancel
        </button>
        <button @click="saveBlogCategory" class="jbtn jbtn-sm">
          <span v-if="isSavingDialog" key="spinner"
            ><i class="fad fa-spinner-third fa-spin"></i
          ></span>
          <span v-else key="button"><i class="fa fa-save"></i> Save</span>
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import C from "@/constants";
import toast from "@/assets/js/toast";
import axios from "axios";

export default {
  name: "BlogCategories",
  data() {
    return {
      isLoading: true,
      isSavingDialog: false,
      showError: false,
      linkNameExists: false,
      validationMsg: "",

      categories: null,

      newCategory: { id: 0, name: "", link_name: "" },
      bluredNewCategory: false,
      bluredCategoryLinkName: false,
      editMode: false,
    };
  },
  methods: {
    getCategories() {
      axios
        .post("/api/blog/functions-blog.php", {
          request: "get_categories",
        })
        .then((response) => {
          //console.log(response.data);
          this.categories = response.data;
          if (!Array.isArray(this.categories) || !this.categories.length) {
            toast.error("Error loading categories from database.");
            this.showError = true;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.message);
          this.isLoading = false;
          this.showError = true;
        });
    },
    showAddCategoryDialog() {
      this.editMode = false;
      this.newCategory = { id: 0, name: "", link_name: "" };
      this.linkNameExists = false;
      this.validationMsg = "";
      this.bluredNewCategory = false;
      this.bluredCategoryLinkName = false;
      this.$refs["add-category-modal"].show();
    },
    showEditCategoryDialog(category) {
      this.editMode = true;
      this.newCategory.link_name = category.link_name;
      this.newCategory.name = category.name;
      this.newCategory.id = category.id;
      this.linkNameExists = false;
      this.validationMsg = "";
      this.$refs["add-category-modal"].show();
    },
    saveBlogCategory() {
      if (!this.isSavingDialog && this.validateCategory()) {
        this.isSavingDialog = true;
        axios
          .post("/api/blog/functions-blog.php", {
            request: this.editMode ? "edit_category" : "add_category",
            id: this.newCategory.id,
            name: this.newCategory.name,
            link_name: this.newCategory.link_name,
          })
          .then((response) => {
            //alert(response.data);
            console.log(response.data);
            //check if valid response, check status
            if (response.data === "OK") {
              toast.success("Category saved.");
              //reload categories
              this.getCategories();
              //reset vars
              this.newCategory = { id: 0, name: "", link_name: "" };
              //close modal
              this.$refs["add-category-modal"].hide();
            } else if (response.data === "LINK_NAME_EXISTS") {
              this.linkNameExists = true;
              this.validationMsg =
                "This link name already exists. Please enter a different link name and try again.";
            } else {
              toast.error("Couldn't save category. " + response.data);
            }
            this.isSavingDialog = false;
          })
          .catch((error) => {
            console.error(error);
            toast.error("Couldn't save category. " + error.message);
            this.isSavingDialog = false;
          });
      }
    },
    deleteBlogCategory(id) {
      // TODO: delete alert, cant delete category with articles
      axios
        .post("/api/blog/functions-blog.php", {
          request: "delete_category",
          id: id,
        })
        .then((response) => {
          //alert(response.data);
          console.log(response.data);
          //check if valid response, check status
          if (response.data === "OK") {
            toast.success("Category deleted.");
            this.getCategories();
          } else if (response.data === "NOT_EMPTY") {
            toast.error(
              "Can't delete category that is not empty. Please remove all articles first."
            );
          } else {
            toast.error(response.data);
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.message);
        });
    },
    validateCategory() {
      this.bluredNewCategory = true;
      if (!this.validInput(this.newCategory.name)) {
        return false;
      }

      this.bluredCategoryLinkName = true;
      if (!this.validLinkName(this.newCategory.link_name)) {
        return false;
      }

      return true;
    },
    validInput(input) {
      return input.trim().length > 0;
    },
    validLinkName(input) {
      var re = /[^a-zA-Z0-9-]/;
      return input.trim().length > 0 && !re.test(input.trim());
    },
  },
  created() {
    this.getCategories();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/blog.scss";
</style>
